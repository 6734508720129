.news-title{
    font-size:15px ;
    font-weight: 700;
    text-align:start;
    color: black;
}
.news-body{
    font-size: 15px;
    font-style: italic;
    text-align: justify;
    color: black
}
.news:hover{
    background-color:rgb(207, 227, 247);
}
.news:link{
    text-decoration: none;
}
.header{
    font-size: xx-large;
    font-weight: 700;
}
.header-container{
    background-color: rgb(35, 35, 82);
    height: 70px;
}
.small-header{
    font-size: x-large;
    font-weight: 700;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    margin-left: 2px
}
.event-location{
    font-size: medium;
    font-weight:600
}
.event-date, .news-date{
    font-size: 15;
    font-style: italic;
    font-family: sans-serif;
    text-decoration: underline;
    /* font-weight: 600; */
}
.event:hover{
    background-color: rgb(207, 227, 247);
}
.site-footer{
    background-color:#222B40;
    /* position:fixed; */
    /* z-index: 1; */
    /* height: 10%;
    width: 20%; */
}
.footer-text{
    font-size: 11px;
    color: white;
    margin-right: 2px;
}
.t-align{
    text-align: justify;
    text-justify: auto;
}

/* Buttons design  */
.read-btn {
    /* background-color: rgb(9, 181, 43); */
    background-color: rgb(35, 35, 82);
}
.read-btn:hover, .read-btn:disabled{
    background-color: rgb(151, 151, 151);
    color: white
}
.more-btn{
    background-color: rgb(35, 35, 82);
    width:9rem;
    height: 50px;
    margin-right: 4px;
    margin-top: 4px;
    

    
}
.more-btn:hover, .read-btn:disabled{
    background-color: rgb(151, 151, 151);
    color: white
}
/* Title Men  */
.title-men:hover{
    background-color:rgb(207, 227, 247);
}
.title-men-name{
    font-size: large;
    font-family: Verdana, Geneva, Tahoma, sans-serif,;
    font-weight: 650;
    font-style: italic;

}
.title-men-title{
    font-size: medium;
    font-weight: 500;
    font-style:oblique;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}


/* Admin Header  */
.side-bar{
    /* margin-top: 60px; */
    position:fixed;
    width: 250px;
    height: 100%;
    z-index: 1;
    top: 0;
    left: 0;
    background-color:  rgb(255, 255, 255);
    overflow-x: hidden;
    overflow-block:clip;
    display: flex;
    flex-direction: column;
    transition: 0.5s;
    /* padding-left: 5rem; */
    border-width: 1px;
    border-style:groove;
    border-color: rgb(152, 152, 171);
}
.side-bar a {
    display: flex;
    text-decoration: none;
    color:rgb(11, 8, 8);
    font-size: 16px;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
    height: 50px;
    border-radius: 5px;
    
}
.side-bar h5{
    color: black
}
.side-bar a:hover{
    color: white;
    background-color: rgb(152, 152, 171);
}
.active{
    text-decoration: none;
    font-variant: small-caps;
    /* background-color: rgb(152, 152, 171); */
}
.active span{
    color: white;
}
.tab{
    text-decoration: none;
    font-variant:normal;
    background-color: rgb(230, 230, 230);
}
.tab span{
    text-decoration: none;
    font-variant:normal;
    color: rgb(7, 5, 5);

}
.side-bar-top{
    background-color: rgb(35, 35, 82);
    position: fixed;
    overflow-y: hidden;
    top: 0;
    z-index: 1;
    width: inherit;
    height: 8%;
}
.side-bar-body{
    /* position: absolute; */
    /* z-index: 0; */
    margin-top: 24%;
    margin-bottom: 20px;
    width:inherit;
    background-color:whitesmoke;
    overflow-y: scroll;
}
.side-bar-bottom{
    /* width: 250px; */
    background-color: rgb(110, 98, 98);
    z-index: 1;
    position: fixed;
    bottom: 0;
    /* display: block; */
    /* margin-bottom: 10px; */
    width: inherit;
    height: 20%;
}
.side-bar-body-section{
    border: 0px;
    border-bottom: 1px;
    border-bottom-color: red;
    border-style: solid;
    border-radius: 3%;
}
.top-bar{
    margin-left: 250px;
    /* width: 100%; */
    height: 60px;
    background-color:  white;
    z-index: 1;
    /* overflow-x: inherit; */
}
.main{
    margin-top: 60px;
    margin-left: 250px;
    overflow-x: hidden;
    background-color: whitesmoke;
}
body{
    overflow-x: hidden ;
    /* overflow-y: auto; */
    height: 100%;
    margin-left: 2px;
    margin-right: 2px;
}
/* html{
    height: 100%;
    overflow:auto;
} */

/* Dictionary CSS  */
.dictionary-table{
        height: 250px;
        overflow-y: 'auto';
        background-color: red;
        position:sticky;
}
.book-title{
    font-size:11px ;
    font-weight: 700;
    text-align:start;
    color: black;
}
.book-body{
    font-size: 12px;
    font-style: italic;
    text-align: justify;
    color: black
}
