/* Created by Tivotal */

/* Google fonts(Poppins) */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

.topBar{
  /* background-color: rgb(197, 207, 213); */
  /* background-color: #B4BEC0; */
  background-color: #B9B9B9;
  font-variant: small-caps
}
.header-btn{
  background-color: #B9B9B9;
  border: 0px solid #B9B9B9;
  color: #0a1f4ff3;
}


.header-btn.active{
  background-color: #B9B9B9;
  color: #0a1f4ff3;
  border-bottom: 2px;
  border-bottom-style: solid;
  border-bottom-color: #0a1f4ff3;
  text-decoration: underline;
}
.header-btn:hover{
  background-color: #B9B9B9;
  color: rgb(112, 56, 255);
}
.header-link-body{
  border: 0px;
  background-color: inherit;
}
.slide-image{
  animation-name: slider-image;
  animation-duration: 4s;
  animation-delay: 0s;
  animation-iteration-count: 1;
}
@keyframes slider-image {
0%{
  /* transform: rotate(0deg); */
  transform: scale(150%);
  opacity: 70%;
}
100%{
  /* transform: rotate(-5deg); */
  transform: scale(100%);
  opacity: 100%;
}
}
.home-contact{
  background-color: rgba(192, 192, 164, 0.884);
}
.login{
  /* background-color: rgba(202, 207, 212) */
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
.table{
  border: 0 solid wheat;
}
/* .dropdown { */
  /* position: relative;
  display: inline-block; */
/* } */

.dropdown-content {
  display: none;
  position: absolute;
  min-width: 115px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  /* padding: 12px 16px; */
  z-index: 9999;
  /* color: black; */
  padding: 5px 5px 5px 5px;
  margin: 2px;
  background-color: white;
}
.open  .dropdown-content {
  display: block;
  border-radius: 10%;
}
.dropdown-content button{
  border: 0;
  font-size: 13px;
  /* background-color: ; */

}
.dropdown-content button:hover{
  /* background-color: whitesmoke; */
  background-image: repeating-conic-gradient(rgb(39, 54, 45), rgb(42, 91, 72),rgb(39, 54, 45),
  rgb(42, 91, 72), rgb(39, 54, 45),
  rgb(42, 91, 72),rgb(39, 54, 45));
  color: white;
  
}
.table-action-box{
  display:none;
  position: absolute;
  min-width: 100px;
  width: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  /* padding: 12px 16px; */
  z-index: 9999;
  /* color: black; */
  /* padding: 5px 5px 5px 5px; */
  /* margin: 2px; */
  background-color: white;
  border: 0 white;
  /* font-size: 13px; */
  /* border-radius: 10%; */
}
.table-action-box.open{
  display:block;
  border-radius: 10px;
  width: inherit;
}
@media  (max-width: 700px ){
  .table-action-box.open{
    display:contents;
    border-radius: 10px;
    background-color: red;
    /* width: 100px; */
  }
}
.table-action-box.open :hover{
  background-image:  repeating-conic-gradient(#222B40, #0a1839,#222B40,
  #B9B9B9, #222B40,
  #041439,#000000);
  color: white;
  /* width:auto */
}


/* Order Table CSS */
 tr {
  border: 0px solid black;
  border-style: groove;
}
.order  td, .order tr{
  font-size: 12px;
  font-weight: 500;
}
.order th{
  /* padding-left: 5px; */
  text-align: center;
}
.order td{
  text-align: center;
}
@media print {

  .order-action{
    display: none;
  }
}
.dashboard-card{
  height: 15vh;
  /* background-color: whitesmoke; */
}
.dashboard-card .title{
  padding-top: 2px;
  padding-left: 5px;
  background-color: rgb(17, 16, 56);
  height: 50%;
  color: white;
}
.dashboard-card .body{
  padding-left: 5px;
  /* background-color: whitesmoke; */
  height: 50%;
  font-size: 15px;
  font-weight: 600;
}
.bg, .btn-color{
  /* background-color: #1F634F; */
  /* background-color:  rgb(39, 54, 45); */
  background-image: repeating-conic-gradient(#222B40, #0a1839,#222B40,
  #8a8989, #222B40,
  #041439,#1f1d1d);
  color: white;
}
.btn-color:hover{
  /* background-image: #708b83; */
  background-image: repeating-conic-gradient(#222B40, #0a1839,#222B40,
  #b3b3b3, #222B40,
  #041439,#000000);
  opacity: 90%;
  color: white
}
.btn{
  /* width: 150px; */
}
#loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  /* background: #ffffff82; */
  background: #faf6f6;
  opacity: 60%;
}
#loader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  /* border: 6px solid rgb(9, 9, 25); */
  background-image: url('/public/abaafashab3.png');
  background-repeat: no-repeat;
  background-size:contain;
  /* border-top-color: white; */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
#newUser input, #newUser select {
  margin-bottom: 5px;
}
#newUser label {
  font-size: 14;
  font-weight: 500;
}
.login{
  /* display: flex;
  justify-content: center;
  align-items:center; */
  /* height: 100%; */
  /* height: 99vh; */
  /* height: calc(100% -2vh); */
  /* color: rgb(255, 255, 255); */
  
  background-image: url('../../../public/abaafashab3.png');
  /* background-repeat:repeat-x; */
  background-size:250px;
  /* border-radius: 10px;  */
  /* background-size:99vh; */
  /* background-position: center;
  background-repeat:round;  */
  /* opacity: 60%; */
  
}
@media (min-width: 971px ) {
  /* .login{
    background-repeat:no-repeat
  } */
}
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 260px;
  /* background-color: #11101d; */
  z-index: 100;
  transition: all 0.5s ease;
}

.sidebar.close {
  width: 78px;
}

.sidebar .logo {
  display: flex;
  align-items: center;
  border-bottom: 1px;
  border-bottom-color: white;
  border-bottom-style: groove;
  padding:4px 0px 14px 0px
}
.sidebar .logo .image {
  height: 50px;
  /* border-style: groove;
  border-color: white;
  border-radius: 40%; */
  margin-right: 5px;
}
.sidebar .logo i {
  font-size: 25px;
  color: #fff;
  /* height: 50px; */
  min-width: 78px;
  text-align: center;
  /* line-height: 50px; */
}

.sidebar .logo .logo-name {
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  transition: all 0.3s ease;
  transition-delay: 0.1s;
}

.sidebar.close .logo .logo-name {
  opacity: 0;
  pointer-events: none;
}

.sidebar .nav-list {
  height: 100%;
  padding: 0px 0 0px 0;
  overflow: auto;
}

.sidebar.close .nav-list {
  overflow: visible;
}

.sidebar .nav-list::-webkit-scrollbar {
  display: none;
}

.sidebar .nav-list li {
  position: relative;
  list-style: none;
  transition: all 0.4s ease;
}

.sidebar .nav-list li:hover {
  background-color: #6e6c8f;
}

.sidebar .nav-list li .icon-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sidebar.close .nav-list li .icon-link {
  display: block;
}

.sidebar .nav-list li i {
  height: 50px;
  min-width: 78px;
  text-align: center;
  line-height: 50px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.sidebar.close .nav-list li i.arrow {
  display: none;
}

/* .sidebar .nav-list li.show i.arrow {
  transform: rotate(-180deg);
} */

.sidebar .nav-list li a {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.sidebar .nav-list li a .link-name {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  transition: all 0.4s ease;
}

.sidebar.close .nav-list li a .link-name {
  opacity: 0;
  pointer-events: none;
}

.sidebar .nav-list li .sub-menu {
  padding: 8px 2px 5px 75px;
  margin-top: -10px;
  background: #222B40;
  display: none;
  transition: all 0.3s ease;
  /* margin-left: 2px; */
}
.sidebar .nav-list li .sub-menu li:hover{
  background-color: #162039;
}

.sidebar .nav-list li.show .sub-menu {
  display: block;
}

.sidebar.close .nav-list li .sub-menu {
  position: absolute;
  left: 100%;
  top: -10px;
  margin-top: 0;
  padding: 10px 20px;
  border-radius: 0 6px 6px 0;
  opacity: 0;
  display: block;
  pointer-events: none;
  transition: 0s;
}

.sidebar.close .nav-list li:hover .sub-menu {
  top: 0;
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
}

.sidebar .nav-list li .sub-menu a {
  color: #fff;
  font-size: 15px;
  padding: 5px 0;
  white-space: nowrap;
  opacity: 0.6;
  transition: all 0.3s ease;
}

.sidebar .nav-list li .sub-menu a:hover {
  opacity: 1;
}

.sidebar .nav-list li .sub-menu .link-name {
  display: none;
}

.sidebar.close .nav-list li .sub-menu .link-name {
  font-size: 18px;
  opacity: 1;
  display: block;
}

.sidebar .nav-list li .sub-menu.blank {
  padding: 3px 20px 6px 16px;
  opacity: 0;
  pointer-events: none;
}

.sidebar .nav-list li:hover .sub-menu.blank {
  top: 50%;
  transform: translateY(-50%);
}

.profile-details {
  position: fixed;
  bottom: 0;
  width: 260px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-image: repeating-conic-gradient(rgb(0, 0, 0), rgb(68, 82, 59), rgb(0, 0, 0)); */
  background-image: url('ux-footer.png');
  background-size:cover;
  background-repeat:round;
  /* background-repeat: repeat-y; */
  padding: 5px 0;
  transition: all 0.5s ease;
}

.sidebar.close .profile-details {
  background-color: none;
  width: 78px;
}

.profile-details .profile-content {
  display: flex;
  align-items: center;
}

.profile-details .profile-content img {
  height: 51px;
  width: 51px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 14px 0 12px;
  background-color: #1d1b31;
  transition: all 0.5s ease;
}

.sidebar.close .profile-details .profile-content img {
  padding: 10px;
}

.profile-details .name-job .name,
.profile-details .name-job .job {
  color: #fff;
  font-size: 18px;
  white-space: nowrap;
}

.sidebar.close .profile-details i,
.sidebar.close .profile-details .name-job .name,
.sidebar.close .profile-details .name-job .job {
  opacity: 0;
  pointer-events: none;
}

.profile-details .name-job .job {
  font-size: 12px;
}

.home-section {
  position: relative;
  height: 100vh;
  width: calc(100% - 260px);
  left: 260px;
  /* background-color: #e4e9f7; */
  transition: all 0.5s ease;
}

.sidebar.close ~ .home-section {
  left: 78px;
  width: calc(100% - 78px);
}

.home-section .home-content {
  position: fixed;
  top: 0;
  display: flex;
  height: 70px;
  width: 100%;
  align-items: center;
  /* background-color: whitesmoke; */
  z-index: 100;
  border-left-color: white;
  border-left-style: groove;
}
.bell:hover{
  border: 1 solid whitesmoke;
  /* background-color: rgb(218, 213, 213); */
  border-radius: 50%;
}
 .sidebar.close ~ .home-section .home-content .bell{
  position: absolute;
  right:120px;
  left: auto;
  text-decoration: none;
  color:rgb(180, 110, 110);
  /* margin-left: calc(100% - 300px); */
  /* width: 50px; */
  height: 50px;
  /* background-color: rgb(74, 51, 51);
  border-radius: 100%; */
}
.sidebar ~ .home-section .home-content .bell{
  position: absolute;
  right:300px;
  left: auto;
  text-decoration: none;
  color:rgb(180, 110, 110);
  /* margin-left: calc(100% - 300px); */
  /* width: 50px; */
  /* height: 50px; */
  /* background-color: rgb(74, 51, 51);
  border-radius: 100%; */
}
.bell .bell-text {
  font-size: 14px;
  position: absolute;
  /* top: 5px; */
  color:#f5f5f5;
  font-weight: 600;
}

.home-section .home-content i,
.home-section .home-content .text {
  color: #11101d;
  font-size: 35px;
}

.home-section .home-content i {
  margin: 0 15px;
  margin-left: 4px;
  cursor: pointer;
}

.home-section .home-content .text {
  /* white-space: nowrap; */
  font-size: 20px;
  font-weight: 600;
}

@media (max-width: 430px) {
  .sidebar.close .nav-list li .sub-menu {
    display: none;
  }

  .sidebar {
    position: fixed;
    width: 260px;
    z-index: 99;
    height: 99%;
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .sidebar.close {
    width: 0;
    z-index: 0;
    display: none;
  }

  .home-section {
    left: 78px;
    position: static;
    width: calc(100% - 260px);
    z-index: 100;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .sidebar.close ~ .home-section {
    width: 100%;
    left: 0;
    z-index: 99;
  }
  .home-section .home-content {
    position: fixed;
    top: 0;
    display: flex;
    height: 70px;
    width: 100%;
    align-items: center;
    /* justify-content: space-evenly; */
    z-index: 100;
  }
  .sidebar.close ~ .home-section .home-content .bell{
    position: absolute;
    right:25px;
  }
  .sidebar ~ .home-section .home-content .bell{
    position: absolute;
    right:25px;
  }
}
